import React, { useEffect, useState } from "react";
import Menu from "../components/layout/Menu";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import AdminService from "../services/admin.service";
import instance from "../utils/axios";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import citysService from "../services/citys.services";
import themeService from "../services/theme.service";
import AdminStats from "./dashBoard/AdminStats";
import AdminBoard from "./dashBoard/AdminBoard";
import Search from "./search/recherche";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import { useTheme, useMediaQuery } from "@mui/material";

export default function Dashboard() {
  const [loading, setLoading] = useState(false);
  const [artCounter, setArtCounter] = useState([]);
  const [artTheme, setArtTheme] = useState([]);
  const [themeCounter, setThemeCounter] = useState([]);
  const [flopTheme, setFlopTheme] = useState([]);
  const [topCities, setTopCities] = useState([]);
  const [flopCities, setFlopCities] = useState([]);
  const [selectedVille, setSelectedVille] = useState([]);
  const [selectedTheme, setSelectedTheme] = useState([]);
  const [villes, setVilles] = useState([]);
  const [themes, setThemes] = useState([]);
  const [initialThemes, setInitialThemes] = useState([]);
  const [initialCitys, setInitialCitys] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // Responsive 

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  // 
  const [infoCreate, setInfoCreate] = useState({
    authors: [],
    city: [],
    tags: [],
    media: [],
    themes: [],
  });
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    setLoading(true);
    instance.get("articles/info-create").then((res) => {
      setVilles(res.data.city);
      setThemes(res.data.themes);
      setInfoCreate({
        authors: res.data.authors,
        city: res.data.city,
        tags: res.data.tags,
        media: res.data.media,
        themes: res.data.themes,
      });
    });
    instance
      .get("themes/all")
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          setThemes(res.data);
          const getIdThemeByIsHomeTrue = res.data.filter((theme) => theme.is_home === true);
          const isHomeActive = getIdThemeByIsHomeTrue.map((theme) => theme.id);
          setSelectedTheme(isHomeActive);
          setInitialThemes(isHomeActive);
        } else {
          console.log(res);
          toast.error("Une erreur est survenue", {
            position: "top-right",
            theme: "dark",
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Une erreur est survenue", {
          position: "top-right",
          theme: "dark",
        });
      });
    citysService
      .getCitys()
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          const getIdCityByIsHomeTrue = res.data.filter((city) => city.is_home === true);
          const isHomeActive = getIdCityByIsHomeTrue.map((city) => city.id);
          setSelectedVille(isHomeActive);
          setVilles(res.data);
          setInitialCitys(isHomeActive);
        } else {
          console.log(res);
          toast.error("Une erreur est survenue", {
            position: "top-right",
            theme: "dark",
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Une erreur est survenue", {
          position: "top-right",
          theme: "dark",
        });
      });
  }, []);
  const caculateWidth = Math.max(410 - (1920 - windowWidth) * 0.3, 0);

  useEffect(() => {
    setLoading(true);
    AdminService.dashboard()
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          if (
            res.data?.articlesCounter !== undefined &&
            res.data?.articlesCounter !== null &&
            res.data?.articlesCounter.length > 0
          ) {
            const articleCounter = res.data?.articlesCounter.sort((a, b) => b.numbers - a.numbers);
            setArtCounter(articleCounter);
          }
          if (res.data?.themes !== undefined && res.data?.themes !== null && res.data?.themes.length > 0) {
            const articleTheme = res.data?.themes.sort((a, b) => b.articleCount - a.articleCount);
            setArtTheme(articleTheme);
          }
          if (
            res.data?.themesCounter !== undefined &&
            res.data?.themesCounter !== null &&
            res.data?.themesCounter.length > 0
          ) {
            const counterTheme = res.data?.themesCounter.sort((a, b) => b.numbers - a.numbers);
            const result = counterTheme.slice(0, 5);
            setThemeCounter(result);
            const flopThemeTmp = counterTheme.reverse();
            const flopTheme = flopThemeTmp.slice(0, 5);
            setFlopTheme(flopTheme);
          }
          if (
            res.data?.citiesCounter !== undefined &&
            res.data?.citiesCounter !== null &&
            res.data?.citiesCounter.length > 0
          ) {
            const citiesNumbers = res.data?.citiesCounter.sort((a, b) => b.numbers - a.numbers);
            const result = citiesNumbers.slice(0, 5);
            setTopCities(result);
            const resultFlopTmp = citiesNumbers.reverse();
            const resultFlop = resultFlopTmp.slice(0, 5);
            setFlopCities(resultFlop);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        toast.error("Une erreur est survenue", {
          position: "top-right",
          theme: "dark",
        });
      });
  }, []);
  const breadcrumbArray = [
    {
      name: "Tableau de bord",
      link: "/dashboard",
    },
  ];

  const themesChange = JSON.stringify(selectedTheme) === JSON.stringify(initialThemes);
  const citysChange = JSON.stringify(selectedVille) === JSON.stringify(initialCitys);
  const findDiff = (arr1, arr2) => {
    const diffArray1 = arr1.filter((elemento) => !arr2.includes(elemento));
    const diffArray2 = arr2.filter((elemento) => !arr1.includes(elemento));
    return diffArray1.concat(diffArray2);
  };

  const handleUpdateThemeAndCity = () => {
    if (!themesChange) {
      const diffArray = findDiff(initialThemes, selectedTheme);

      const promisesThemes = diffArray.map((themeId) => {
        const findTheme = themes.find((theme) => theme.id === themeId);
        const changeIsHome = { ...findTheme, is_home: !findTheme.is_home, is_active: true };
        return themeService
          .updateTheme(changeIsHome)
          .then((res) => {
            if (res.status === 200) {
              if (changeIsHome.is_home) {
                setInitialThemes([...initialThemes, changeIsHome.id]);
              } else {
                setInitialThemes(initialThemes.filter((theme) => theme !== changeIsHome.id));
              }
            } else {
              return { error: true };
            }
          })
          .catch((error) => {
            return { error: true };
          });
      });
      Promise.all(promisesThemes)
        .then((results) => {
          const hasErrorThemes = results.some((result) => result && result.error);
          if (hasErrorThemes) {
            toast.error("Une erreur est survenue", {
              position: "top-right",
              theme: "dark",
            });
          } else {
            toast.success("Thème modifié avec succès", {
              position: "top-right",
              theme: "dark",
            });
          }
        })
        .catch((error) => {
          console.error("Error al procesar las promesas de temas:", error);
        });
    }
    if (!citysChange) {
      // eslint-disable-next-line array-callback-return
      const diffArray = findDiff(initialCitys, selectedVille);
      const promises = diffArray.map((cityId) => {
        const findCity = villes.find((city) => city.id === cityId);
        const changeIsHome = { id: findCity.id, is_home: !findCity.is_home };
        return citysService
          .updateCity(changeIsHome)
          .then((res) => {
            if (res.status === 200) {
              if (changeIsHome.is_home) {
                setInitialCitys([...initialCitys, changeIsHome.id]);
              } else {
                setInitialCitys(initialCitys.filter((city) => city !== changeIsHome.id));
              }
            } else {
              return { error: true };
            }
          })
          .catch((error) => {
            return { error: true };
          });
      });
      Promise.all(promises)
        .then((results) => {
          const hasError = results.some((result) => result && result.error);
          if (hasError) {
            toast.error("Une erreur est survenue", {
              position: "top-right",
              theme: "dark",
            });
          } else {
            toast.success("Ville modifié avec succès", {
              position: "top-right",
              theme: "dark",
            });
          }
        })
        .catch((error) => {
          console.error("Error al procesar las promesas:", error);
        });
    }
  };
  function CustomTabPanel(props) {
    const { children, value, index, padding, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: isSmallScreen ? padding : 3, paddingTop: "2rem" }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Menu category="Accueil" breadCrumb={breadcrumbArray} link="/articles/create" buttonText="Créer un article">
      {loading && <Loader />}
      <Tabs value={value} onChange={handleChange} aria-label="basic tabs example"
      >
        <Tab sx={
          {
            backgroundColor: value === 0 ? "#f5f5f5" : "inherit",
          }
        } label="Statistiques" {...a11yProps(0)} />
        <Tab
          sx={
            {
              backgroundColor: value === 1 ? "#f5f5f5" : "inherit",
            }
          } label="Administration des modules" {...a11yProps(1)} />
        {/*<Tab*/}
        {/*  sx={*/}
        {/*    {*/}
        {/*      backgroundColor: value === 1 ? "#f5f5f5" : "inherit",*/}
        {/*    }*/}
        {/*  } label="Recherche" {...a11yProps(2)} />*/}
      </Tabs>

      <CustomTabPanel
        padding={3}
        sx={{ width: "100%" }}
        value={value} index={0}>
        <AdminStats
          artCounter={artCounter}
          artTheme={artTheme}
          themeCounter={themeCounter}
          flopTheme={flopTheme}
          topCities={topCities}
          flopCities={flopCities}
        />
      </CustomTabPanel>

      <CustomTabPanel
        padding={1.3}
        value={value}
        index={1}
        sx={{ width: "100%" }}

      >
        <AdminBoard
          padding={1}
          themes={themes}
          villes={villes}
          selectedTheme={selectedTheme}
          setSelectedTheme={setSelectedTheme}
          selectedVille={selectedVille}
          setSelectedVille={setSelectedVille}
          themesChange={themesChange}
          citysChange={citysChange}
          handleUpdateThemeAndCity={handleUpdateThemeAndCity}
          infoCreate={infoCreate}
          setInfoCreate={setInfoCreate}
          caculateWidth={caculateWidth}
        />
      </CustomTabPanel>

      <CustomTabPanel
        padding={3}
        sx={{ width: "100%" }}
        value={value} index={2}>
        <Search />

      </CustomTabPanel>


    </Menu>
  );
}
