import instance from "../utils/axios";

class articles {
  constructor() {
    this.instance = instance;
  }
  getArticles = async () => {
    return await instance.get("articles/all", {
      withCredentials: true,
    });
  };

  getArticle = async (id) => {
    return await instance.get(`articles/one?id=${id}`, {
      withCredentials: true,
    });
  };

  createArticle = async (data) => {
    return await instance.post(
      "articles/create",
      data
      // {
      //   title: data.title,
      //   description: data.description,
      //   body: data.body,
      //   publication_date: data.publication_date,
      //   is_active: data.is_active,
      //   city_id: data.city_id,
      //   author_id: data.author_id,
      //   theme_id: data.theme_id,
      //   tag_id: data.tag_id,
      //   media_id: data.media_id,
      // }
    );
  };

  updateArticle = async (values) => {
    return await instance.put("articles/update/", values, {
      withCredentials: true,
    });
  };
  getNotificationsById = async (id) => {
    return await instance.get(`articles/send-notification?id=${id}`, {
      withCredentials: true,
    });
  }

  deleteArticle = async (id) => {
    return await instance.delete(`articles/delete?id=${id}`,
      {
        withCredentials: true,
      }
    );
  };

  searchContent = async (str) => {
    return await instance.post(`/articles/search`, { text: str },
      {
        withCredentials: true,
      });
  };
}

var articlesService = new articles();
export default articlesService;
